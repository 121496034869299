.locations {
	text-align: center;
	&__inner {

	}
	&__wrap {
		padding-top: em(80);
		@include susy-media($s) {
			padding: em(60) em(20) 0 em(20);
		}
	}
	li {
		display: inline-block;
		&:first-child {
			margin-right: gutter(6);
			@include susy-media($s) {
				margin-right: 0;
			}
		}
		@include susy-media($s) {
			//text-align: left;
			margin-bottom: em(30);
		}
	}
	&__addresses {
		padding: 0;
		margin: em(90) 0 em(40);
		list-style: none;
		@include susy-media($s) {
			margin: em(40) 0;
		}
		h2 {
			text-transform: uppercase;
			font-size: rem(16);
			color: $color__text;
		}
		address {
			font-style: normal;
			opacity: 0.5;
			font-size: em(15);
		}
	}
	&__contact {
		opacity: 0.5;
		margin-bottom: em(80);
		font-size: rem(15);
		@include susy-media($s) {
			font-size: rem(18);
			margin-bottom: em(40);
		}
		span {
			margin: 0 em(6);
			@include susy-media($s) {
				display: block;
				font-size: 0;
				height: 1px;
				margin: 0 0 10px;
			}
		}
		a {
			color: $color__text;
			&:hover {
				text-decoration: none;
			}
			&[href^=tel] {
				text-decoration: none;
			}
		}
	}
}
