.btn {
	background-color: $color__primary;
	color: #fff;
	text-decoration: none;
	font-size: rem(21);
	padding: em(16) em(40);
	text-transform: uppercase;
	border-radius: $border-radius8;
	font-weight: $medium;
	display: inline-block;
	text-align: center;
	&--apply {
		min-width: 440px;
		@include susy-media($s) {
			min-width: 0;
			width: 100%;
			padding-right: 0;
			padding-left: 0;
			font-size: rem(18);
		}
		@include susy-media($ipp) {
			min-width: 0;
		}
	}
}
