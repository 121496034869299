$support-for: (
  chrome:  -4,
  edge:    -4,
  firefox: -4,
  ie:      9,
  opera:   -4,
  safari:  -4,
  '*':     -4,
) !default;

$font-family: "effra", sans-serif;
$font-family-heading: "effra", sans-serif;
$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;

$border-radius: 4px;
$border-radius8: 8px;

// -----------------------------------------------------------------------------
// Colors
// -----------------------------------------------------------------------------
$color__text: #3B3D3A;
$color__primary: #E64331;
$color__title: #3B3D3A;
$color__listhead: #FBFFA2;
$color__border: #D8D8D8;

// -----------------------------------------------------------------------------
// Grids
// -----------------------------------------------------------------------------
$gutter: 40px;
$gutters : $gutter/80px;

$susy: (
	columns: 12,
	column-width: 80px,
	gutters: $gutters,
	math: fluid,
	output: float,
	debug: (
		image: hide,
    color: rgba(#66f, .25)
   ),
	container: 1440px,
	global-box-sizing: border-box
);

$s: (max-width: 767px);
$sl: (max-width: 767px, orientation: landscape);
$sp: (max-width: 1024px);
$dsk: (min-width: 1025px);
$ipp: (min-width: 768px, max-width: 1024px);
$ippo: (min-width: 768px, max-width: 1024px, orientation: portrait);
