.job {
	&__header {
		margin-top: -200px;
		@include susy-media($s) {
			margin-top: -72px;
			//padding: 0 em(20);
		}
		@include susy-media($ippo) {
			margin-top: -176px;
		}
	}
	&__badge {
		font-size: rem(17);
		color: $color__primary;
		text-transform: uppercase;
		font-weight: $bold;
		display: inline-block;
		background-color: #fff;
		box-shadow: em(23,17) 0 0 #fff, em(-23,17) 0 0 #fff;
		padding: em(10,17) 0 0;
	}
	&__title {
		margin: 0;
		line-height: 1.1;
		@include susy-media($s) {
			line-height: 1;
		}
		&-inline {
			background-color: #fff;
			box-shadow: em(23,93) 0 0 #fff, em(-23,93) 0 0 #fff;
			box-decoration-break: clone;
			@include susy-media($s) {
				box-shadow: em(23,38) 0 0 #fff, em(-23,38) 0 0 #fff;
			}
		}
	}
	&__subtitle {
		font-size: rem(27);
		color: $color__title;
		margin-bottom: em(40,27);
		@include susy-media($s) {
			font-size: rem(20);
		}
	}
	&__p10 {
		@extend .clearfix;
		h2 {

		}
		p {
			font-size: rem(20);
			font-weight: $regular;
			padding-right: em(50,20);
			margin-bottom: em(35,20);
			@include susy-media($s) {
				font-size: rem(18);
				padding-right: 0;
			}
		}
	}
	&__stamp {
		margin: em(50) 0;
		position: relative;
		@include susy-media($s) {
			margin: em(50) 0;
		}
		&:after,
		&:before {
			content: open-quote;
			font-size: rem(150);
			color: $color__primary;
			line-height: 1;
			display: block;
			height: em(75, 150);
			position: absolute;
			left: 10%;
			top: 100%;
			@include susy-media($s) {
				font-size: rem(100);
				margin-top: em(10, 100);
			}
		}
		&:after {
			content: close-quote;
			left: auto;
			right: 10%;
			top: auto;
			bottom: 100%;
			margin-top: 0;
		}
		&-q {
			font-size: rem(29);
			color: $color__primary;
			font-weight: $regular;
			font-style: italic;
			@include susy-media($s) {
				font-size: rem(20);
				padding: 0 em(20,20);
			}
		}
	}
	&__content {
		margin-top: em(50);
		&-text {
			margin-bottom: em(70);
			p {
				font-size: rem(20);
				font-weight: $regular;
				margin-bottom: em(30,20);
			}
		}
		&-header {
			p {
				font-weight: $regular;
			}

			h3{
				font-size: 34px;
				font-weight: $bold;
				background-color: $color__listhead;
				display: inline;
				margin-bottom: 44px;
				line-height: 1.6;
			}
		}

		&-listhead {
			border-bottom: 4px solid;
			border-color: $color__listhead;
			font-weight: $medium;
			font-size: rem(20);
			display: inline-block;
			margin-left: em(-20,20);
			margin-top: em(40,20);
			margin-bottom: em(20,20);
			@include susy-media($s) {
				margin-right: em(-20,20);
			}
		}
		&-list {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				font-size: rem(20);
				font-weight: $regular;
				margin-bottom: em(30,20);
				position: relative;
				color: #65605d;

				strong{
					font-weight: $bold;
					color: #252423;
				}
				&:before {
					content: "";
					width: em(34,20);
					height: em(4,20);
					background-color: $color__border;
					display: inline-block;
					position: absolute;
					right: 100%;
					top: em(15,20);
					margin-right: em(25,20);
					@include susy-media($ippo) {
						margin-right: em(16,20);
					}
				}
			}
			&--stars {
				li {
					&:before {
						background: url("../img/star.svg");
						width: em(22,20);
						height: em(20,20);
						top: em(5,20);
						margin-right: em(20,20);
						@include susy-media($ippo) {
							margin-right: em(25,20);
						}
					}
				}
			}
		}
	}
	&-apply {
		position: relative;
		margin-bottom: em(70);
		@include susy-media($s) {
			margin-right: em(-20);
			margin-left: em(-20);
		}
		&__link {
			position: relative;
			margin:0 auto;
			text-align: center;
			top: 50%;

			&-description{
				font-size: rem(24);
				margin: 0;
				padding: 0;
			}

			@include susy-media($s) {
				position: relative;
				margin-left: 0;
				@include transform(translateY(0));

				&-description{
					font-size: rem(26);
				}
			}
			@include susy-media($ipp) {
				left: em(70);
				margin-left: 0;

			}
			@include susy-media($ippo) {
				margin-left: 0;
				text-align: center;
			}
		}
		&__list {
			float: right;
			margin-right: em(30);
			@include susy-media($s) {
				margin: 0;
				float: none;
				text-align: center;
				margin-top: em(50);
			}
			@include susy-media($ippo) {
				margin: 0;
				float: none;
				text-align: center;
				margin-top: em(50);
			}
		}
		&__inner {
			background: url("../img/pattern-bulb.png");
			padding: em(54);
			color: #fff;
			@extend .clearfix;
			@include susy-media($s) {
				padding: em(50) em(30);
			}
		}
		&__other {
			h4 {
				text-transform: uppercase;
				color: $color__primary;
				font-size: rem(12);
				font-weight: $regular;
			}
		}
		&__nav {
			padding: 0;
			margin: 0 0 em(12);
			list-style: none;
			a {
				font-size: rem(24);
				color: #fff;
				font-weight: $bold;
				&:hover {
					text-decoration: none;
				}
			}
		}
		&__all {
			font-size: rem(14);
			color: rgba(#fff, 0.5);
		}
	}
}
