.copy {
	text-align: center;
	margin-bottom: em(170);
	@include susy-media($s) {
		margin-bottom: em(40);
	}
	img {
		margin-bottom: em(15);
	}
	p {
		margin: 0 0 em(8);
		font-size: rem(15);
		opacity: 0.2;
	}
}
