html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "effra", sans-serif;
  color: #3B3D3A;
  font-size: 100%;
  line-height: 1.6875;
  font-weight: 400;
}

p {
  font-weight: 300;
}

p u {
  text-decoration: underline;
}

.clearfix:after, .header__jobs-list:after, .job__p10:after, .job-apply__inner:after {
  content: "";
  display: table;
  clear: both;
}

h1 {
  font-size: 5.8125rem;
  font-weight: 700;
  color: #3B3D3A;
  font-family: "effra", sans-serif;
  line-height: 1.21;
}

@media (max-width: 767px) {
  h1 {
    font-size: 2.375rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  h1 {
    font-size: 4.375rem;
  }
}

h2 {
  text-transform: uppercase;
  color: #E64331;
  font-size: 1.5rem;
  font-weight: 700;
}

h2 small {
  text-transform: lowercase;
}

.hidden {
  display: none !important;
}

.header__inner {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.header__inner:after {
  content: " ";
  display: block;
  clear: both;
}

.header__jobs {
  width: 65.71429%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .header__jobs {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header__jobs {
    width: 82.85714%;
  }
}

.header__jobs-list li {
  width: 73.91304%;
  float: left;
  margin-right: 4.34783%;
  margin-left: 13.04348%;
}

@media (max-width: 767px) {
  .header__jobs-list li {
    width: auto;
    float: none;
    margin: 0;
  }
}

.header__description {
  width: 48.57143%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .header__description {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header__description {
    width: 65.71429%;
  }
}

.locations__inner {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.locations__inner:after {
  content: " ";
  display: block;
  clear: both;
}

.locations__wrap {
  width: 48.57143%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .locations__wrap {
    width: auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .locations__wrap {
    width: 65.71429%;
  }
}

.locations li {
  width: 29.41176%;
}

@media (max-width: 767px) {
  .locations li {
    width: 100%;
  }
}

.main-content__inner {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.main-content__inner:after {
  content: " ";
  display: block;
  clear: both;
}

.main-content__wrap {
  width: 82.85714%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .main-content__wrap {
    width: auto;
    padding: 0 1.25em;
  }
}

.job__header, .job__p10 {
  width: 79.31034%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .job__header, .job__p10 {
    width: auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .job__header, .job__p10 {
    width: 100%;
  }
}

.job__header-item, .job__p10-item {
  width: 47.82609%;
  float: left;
  margin-right: 4.34783%;
}

.job__header-item:last-child, .job__p10-item:last-child {
  float: right;
  margin-right: 0;
}

@media (max-width: 767px) {
  .job__header-item, .job__p10-item {
    width: auto;
    margin: 0;
    float: none;
  }
}

.job__stamp-q {
  width: 58.62069%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .job__stamp-q {
    width: auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .job__stamp-q {
    width: 73.91304%;
  }
}

.job__content {
  clear: both;
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
}

.job__content-text {
  width: 58.62069%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .job__content-text {
    width: auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .job__content-text {
    width: 100%;
  }
}

.btn {
  background-color: #E64331;
  color: #fff;
  text-decoration: none;
  font-size: 1.3125rem;
  padding: 1em 2.5em;
  text-transform: uppercase;
  border-radius: 8px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
}

.btn--apply {
  min-width: 440px;
}

@media (max-width: 767px) {
  .btn--apply {
    min-width: 0;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    font-size: 1.125rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .btn--apply {
    min-width: 0;
  }
}

.header {
  position: relative;
  background: url("../img/pattern.png");
  overflow: hidden;
  padding-bottom: 4.375em;
}

.header.header--job {
  background: url("../img/pattern-light.png") repeat-x 50% 0;
  height: 515px;
  padding: 0;
}

@media (max-width: 767px) {
  .header.header--job {
    height: 360px;
    background-repeat: repeat;
    background-position: 50% 27px;
  }
}

.header.header--job:after {
  content: "";
  height: 65px;
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 0;
}

@media (max-width: 767px) {
  .header.header--job:after {
    display: none;
  }
}

.header__inner {
  position: relative;
  z-index: 1;
}

.header--job .header__inner {
  z-index: 2;
}

.header__logo {
  text-align: center;
  width: 146px;
  height: 36px;
  margin: 0 auto;
  padding-top: 2.5em;
  margin-bottom: 14.375em;
  box-sizing: content-box;
}

@media (max-width: 767px) {
  .header__logo {
    padding-top: 3.75em;
    margin-bottom: 8.75em;
  }
}

.header__careers {
  background: url("../img/careers.svg") no-repeat 0 0;
  position: absolute;
  left: 50%;
  top: -20px;
  width: 1534px;
  height: 517px;
  margin-left: -767px;
  z-index: 0;
}

.header--job .header__careers {
  background-image: url("../img/careers-b.svg");
  z-index: 1;
}

@media (max-width: 1024px) {
  .header__careers {
    left: -5%;
    width: 110%;
    background-size: contain;
    height: auto;
    padding-top: 100%;
    top: 90px;
    margin-left: 0;
  }
}

.header__jobs-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 767px) {
  .header__jobs-list {
    padding: 0 0.5em;
  }
}

.header__jobs-list a {
  color: #fff;
  font-size: 2.5rem;
  font-weight: 700;
  display: inline;
  line-height: 1.2;
  text-decoration: none;
  box-shadow: 0 -6px 0 0 #E64331 inset, 0 -8px 0 rgba(255, 255, 255, 0.2) inset;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header__jobs-list a:hover {
  box-shadow: 0 -4px 0 0 #E64331 inset, 0 -8px 0 rgba(255, 255, 255, 0.9) inset;
}

@media (max-width: 767px) {
  .header__jobs-list a {
    font-size: 2.375rem;
  }
}

.header__jobs-list li {
  position: relative;
  background-color: #E64331;
  border-radius: 4px;
  padding: 2em 2em 5.625em;
  margin-bottom: 2.5em;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (max-width: 767px) {
  .header__jobs-list li {
    margin-bottom: 0.5em;
  }
}

.header__jobs-list li:nth-child(2n) {
  margin-right: 0;
}

.header__jobs-list li:hover {
  -webkit-transform: scale(1.08);
  -moz-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -o-transform: scale(1.08);
  transform: scale(1.08);
}

.header__jobs-list .what {
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
  display: inline-block;
  margin-top: 18px;
}

@media (max-width: 767px) {
  .header__jobs-list .what {
    margin-bottom: 40px;
  }
}

.header__jobs-list .when {
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 2em;
  left: 2em;
}

.header__description {
  color: #fff;
  font-size: 1.6875rem;
  margin-top: 3.125em;
}

@media (max-width: 767px) {
  .header__description {
    font-size: 1.125rem;
    line-height: 1.6;
    padding: 0 1.17647em;
    margin-top: 1.875em;
  }
}

.header__description a {
  color: #FFF;
  font-weight: 700;
}

.locations {
  text-align: center;
}

.locations__wrap {
  padding-top: 5em;
}

@media (max-width: 767px) {
  .locations__wrap {
    padding: 3.75em 1.25em 0 1.25em;
  }
}

.locations li {
  display: inline-block;
}

.locations li:first-child {
  margin-right: 5.88235%;
}

@media (max-width: 767px) {
  .locations li:first-child {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .locations li {
    margin-bottom: 1.875em;
  }
}

.locations__addresses {
  padding: 0;
  margin: 5.625em 0 2.5em;
  list-style: none;
}

@media (max-width: 767px) {
  .locations__addresses {
    margin: 2.5em 0;
  }
}

.locations__addresses h2 {
  text-transform: uppercase;
  font-size: 1rem;
  color: #3B3D3A;
}

.locations__addresses address {
  font-style: normal;
  opacity: 0.5;
  font-size: 0.9375em;
}

.locations__contact {
  opacity: 0.5;
  margin-bottom: 5em;
  font-size: 0.9375rem;
}

@media (max-width: 767px) {
  .locations__contact {
    font-size: 1.125rem;
    margin-bottom: 2.5em;
  }
}

.locations__contact span {
  margin: 0 0.375em;
}

@media (max-width: 767px) {
  .locations__contact span {
    display: block;
    font-size: 0;
    height: 1px;
    margin: 0 0 10px;
  }
}

.locations__contact a {
  color: #3B3D3A;
}

.locations__contact a:hover {
  text-decoration: none;
}

.locations__contact a[href^=tel] {
  text-decoration: none;
}

.copy {
  text-align: center;
  margin-bottom: 10.625em;
}

@media (max-width: 767px) {
  .copy {
    margin-bottom: 2.5em;
  }
}

.copy img {
  margin-bottom: 0.9375em;
}

.copy p {
  margin: 0 0 0.5em;
  font-size: 0.9375rem;
  opacity: 0.2;
}

.main-content {
  position: relative;
  z-index: 2;
}

.job__header {
  margin-top: -200px;
}

@media (max-width: 767px) {
  .job__header {
    margin-top: -72px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .job__header {
    margin-top: -176px;
  }
}

.job__badge {
  font-size: 1.0625rem;
  color: #E64331;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-color: #fff;
  box-shadow: 1.35294em 0 0 #fff, -1.35294em 0 0 #fff;
  padding: 0.58824em 0 0;
}

.job__title {
  margin: 0;
  line-height: 1.1;
}

@media (max-width: 767px) {
  .job__title {
    line-height: 1;
  }
}

.job__title-inline {
  background-color: #fff;
  box-shadow: 0.24731em 0 0 #fff, -0.24731em 0 0 #fff;
  box-decoration-break: clone;
}

@media (max-width: 767px) {
  .job__title-inline {
    box-shadow: 0.60526em 0 0 #fff, -0.60526em 0 0 #fff;
  }
}

.job__subtitle {
  font-size: 1.6875rem;
  color: #3B3D3A;
  margin-bottom: 1.48148em;
}

@media (max-width: 767px) {
  .job__subtitle {
    font-size: 1.25rem;
  }
}

.job__p10 p {
  font-size: 1.25rem;
  font-weight: 400;
  padding-right: 2.5em;
  margin-bottom: 1.75em;
}

@media (max-width: 767px) {
  .job__p10 p {
    font-size: 1.125rem;
    padding-right: 0;
  }
}

.job__stamp {
  margin: 3.125em 0;
  position: relative;
}

@media (max-width: 767px) {
  .job__stamp {
    margin: 3.125em 0;
  }
}

.job__stamp:after, .job__stamp:before {
  content: open-quote;
  font-size: 9.375rem;
  color: #E64331;
  line-height: 1;
  display: block;
  height: 0.5em;
  position: absolute;
  left: 10%;
  top: 100%;
}

@media (max-width: 767px) {
  .job__stamp:after, .job__stamp:before {
    font-size: 6.25rem;
    margin-top: 0.1em;
  }
}

.job__stamp:after {
  content: close-quote;
  left: auto;
  right: 10%;
  top: auto;
  bottom: 100%;
  margin-top: 0;
}

.job__stamp-q {
  font-size: 1.8125rem;
  color: #E64331;
  font-weight: 400;
  font-style: italic;
}

@media (max-width: 767px) {
  .job__stamp-q {
    font-size: 1.25rem;
    padding: 0 1em;
  }
}

.job__content {
  margin-top: 3.125em;
}

.job__content-text {
  margin-bottom: 4.375em;
}

.job__content-text p {
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1.5em;
}

.job__content-header p {
  font-weight: 400;
}

.job__content-header h3 {
  font-size: 34px;
  font-weight: 700;
  background-color: #FBFFA2;
  display: inline;
  margin-bottom: 44px;
  line-height: 1.6;
}

.job__content-listhead {
  border-bottom: 4px solid;
  border-color: #FBFFA2;
  font-weight: 500;
  font-size: 1.25rem;
  display: inline-block;
  margin-left: -1em;
  margin-top: 2em;
  margin-bottom: 1em;
}

@media (max-width: 767px) {
  .job__content-listhead {
    margin-right: -1em;
  }
}

.job__content-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.job__content-list li {
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1.5em;
  position: relative;
  color: #65605d;
}

.job__content-list li strong {
  font-weight: 700;
  color: #252423;
}

.job__content-list li:before {
  content: "";
  width: 1.7em;
  height: 0.2em;
  background-color: #D8D8D8;
  display: inline-block;
  position: absolute;
  right: 100%;
  top: 0.75em;
  margin-right: 1.25em;
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .job__content-list li:before {
    margin-right: 0.8em;
  }
}

.job__content-list--stars li:before {
  background: url("../img/star.svg");
  width: 1.1em;
  height: 1em;
  top: 0.25em;
  margin-right: 1em;
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .job__content-list--stars li:before {
    margin-right: 1.25em;
  }
}

.job-apply {
  position: relative;
  margin-bottom: 4.375em;
}

@media (max-width: 767px) {
  .job-apply {
    margin-right: -1.25em;
    margin-left: -1.25em;
  }
}

.job-apply__link {
  position: relative;
  margin: 0 auto;
  text-align: center;
  top: 50%;
}

.job-apply__link-description {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .job-apply__link {
    position: relative;
    margin-left: 0;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .job-apply__link-description {
    font-size: 1.625rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .job-apply__link {
    left: 4.375em;
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .job-apply__link {
    margin-left: 0;
    text-align: center;
  }
}

.job-apply__list {
  float: right;
  margin-right: 1.875em;
}

@media (max-width: 767px) {
  .job-apply__list {
    margin: 0;
    float: none;
    text-align: center;
    margin-top: 3.125em;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .job-apply__list {
    margin: 0;
    float: none;
    text-align: center;
    margin-top: 3.125em;
  }
}

.job-apply__inner {
  background: url("../img/pattern-bulb.png");
  padding: 3.375em;
  color: #fff;
}

@media (max-width: 767px) {
  .job-apply__inner {
    padding: 3.125em 1.875em;
  }
}

.job-apply__other h4 {
  text-transform: uppercase;
  color: #E64331;
  font-size: 0.75rem;
  font-weight: 400;
}

.job-apply__nav {
  padding: 0;
  margin: 0 0 0.75em;
  list-style: none;
}

.job-apply__nav a {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 700;
}

.job-apply__nav a:hover {
  text-decoration: none;
}

.job-apply__all {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
}
