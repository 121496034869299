.header {
	&__inner {
		@include container;
	}
	&__jobs {
		width: span(8);;
		margin: 0 auto;
		@include susy-media($s) {
			width: 100%;
		}
		@include susy-media($ipp) {
			width: span(10);
		}
		&-list {
			li {
				@include span(6 of 8);
				@include push(1 of 8);
				@include susy-media($s) {
					width: auto;
					float: none;
					margin: 0;
				}
			}
		}
	}
	&__description {
		width: span(6);
		margin: 0 auto;
		@include susy-media($s) {
			width: 100%;
		}
		@include susy-media($ipp) {
			width: span(8);
		}
	}
}
.locations {
	&__inner {
		@include container;
	}
	&__wrap {
		width: span(6);
		margin: 0 auto;
		@include susy-media($s) {
			width: auto;
		}
		@include susy-media($ippo) {
			width: span(8);
		}
	}
	li {
		width: span(2 of 6);
		@include susy-media($s) {
			width: 100%;
		}
	}
}
.main-content {
	&__inner {
		@include container;
	}
	&__wrap {
		width: span(10);
		margin: 0 auto;
		@include susy-media($s) {
			width: auto;
			padding: 0 em(20);
		}
	}
}
.job {
	&__header,
	&__p10 {
		width: span(8 of 10);
		margin: 0 auto;
		@include susy-media($s) {
			width: auto;
		}
		@include susy-media($ipp) {
			width: span(8 of 8);
		}
		&-item {
			@include span(4 of 8);
			&:last-child {
				@include last;
			}
			@include susy-media($s) {
				width: auto;
				margin: 0;
				float: none;
			}
		}
	}
	&-apply {
		//margin-left: -(span(1 of 6));
		//margin-right: -(span(1 of 6));
	}
	&__stamp {
		&-q {
			width: span(6 of 10);
			margin: 0 auto;
			@include susy-media($s) {
				width: auto;
			}
			@include susy-media($ipp) {
				width: span(6 of 8);
			}
		}
	}
	&__content {
		@include full;
		&-text {
			width: span(6 of 10);
			margin: 0 auto;
			@include susy-media($s) {
				width: auto;
			}
			@include susy-media($ipp) {
				width: span(6 of 6);
			}
		}
	}
}
