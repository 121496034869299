// base.scss

html, body {
	height: 100%;
	margin: 0;
	padding: 0;
}
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
	@include font-body;
	color: $color__text;
	font-size: 100%;
	line-height: 1.6875;
	font-weight: $regular;
}
p {
	font-weight: $light;
	u {
		text-decoration: underline;
	}
}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
h1 {
	font-size: rem(93);
	font-weight: $bold;
	color: $color__title;
	@include font-heading;
	line-height: 1.21;
	@include susy-media($s) {
		font-size: rem(38);
	}
	@include susy-media($ippo) {
		font-size: rem(70);
	}
}
h2 {
	text-transform: uppercase;
	color: $color__primary;
	font-size: rem(24);
	font-weight: $bold;
	small {
		text-transform: lowercase;
	}
}
.hidden {
	display: none !important;
}
