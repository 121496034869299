.header {
	position: relative;
	background: url("../img/pattern.png");
	overflow: hidden;
	padding-bottom: em(70);
	&.header--job {
		background: url("../img/pattern-light.png") repeat-x 50% 0;
		height: 515px;
		padding: 0;
		@include susy-media($s) {
			height: 360px;
			background-repeat: repeat;
			background-position: 50% 27px;
		}
		&:after {
			content: "";
			height: 65px;
			width: 100%;
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: #fff;
			z-index: 0;
			@include susy-media($s) {
				display: none;
			}
		}
	}
	&__inner {
		position: relative;
		z-index: 1;
		.header--job & {
			z-index: 2;
		}
	}
	&__logo {
		text-align: center;
		width: 146px;
		height: 36px;
		margin: 0 auto;
		padding-top: em(40);
		margin-bottom: em(230);
		box-sizing: content-box;
		@include susy-media($s) {
			padding-top: em(60);
			margin-bottom: em(140);
		}
	}
	&__careers {
		background: url("../img/careers.svg") no-repeat 0 0;
		position: absolute;
		left: 50%;
		top: -20px;
		width: 1534px;
		height: 517px;
		margin-left: -(1534px / 2);
		z-index: 0;
		.header--job & {
			background-image: url("../img/careers-b.svg");
			z-index: 1;
		}
		@include susy-media($sp) {
			left: -5%;
			width: 110%;
			background-size: contain;
			height: auto;
			padding-top: 100%;
			top: 90px;
			margin-left: 0;
		}
	}
	&__jobs {
		&-list {
			list-style: none;
			padding: 0;
			margin: 0;
			@extend .clearfix;
			@include susy-media($s) {
				padding: 0 em(8);
			}
			a {
				color: #fff;
				font-size: rem(40);
				font-weight: $bold;
				display: inline;
				line-height: 1.2;
				text-decoration: none;
				box-shadow: 0 -6px 0 0 $color__primary inset, 0 -8px 0 rgba(255,255,255,.2) inset;
				//@include linear-gradient(transparent 50px, #fff 50px, transparent 52px);
				@include transition(all 0.3s ease);
				&:hover {
				  box-shadow: 0 -4px 0 0 $color__primary inset, 0 -8px 0 rgba(255,255,255,.9) inset;
				}
				@include susy-media($s) {
					font-size: rem(38);
				}
			}
			li {
				position: relative;
				background-color: $color__primary;
				border-radius: $border-radius;
				padding: em(32) em(32) em(90);
				margin-bottom: em(gutter(8 static));
				@include susy-media($s) {
					margin-bottom: em(8);
				}
				&:nth-child(2n) {
					margin-right: 0;
				}
				@include transition(all 0.3s ease);
				&:hover {
					@include transform(scale(1.08));
				}
			}
			.what{
				color: rgba(#fff, 0.8);
				font-size: 24px;
				display: inline-block;
				margin-top: 18px;
				@include susy-media($s) {
					margin-bottom: 40px;
				}
			}
			.when {
				color: rgba(#fff, 0.5);
				position: absolute;
				bottom: em(32);
				left: em(32);
				@include susy-media($s) {
					// position: relative;
					// bottom: 0;
					// left: 0;
					// display: block;
					// margin-top: em(15);
				}
			}
		}
	}
	&__description {
		color: #fff;
		font-size: rem(27);
		margin-top: em(50);
		@include susy-media($s) {
			font-size: rem(18);
			line-height: 1.6;
			padding: 0 em(20,17);
			margin-top: em(30);
		}

		a{
			color: #FFF;
			font-weight: $bold;
		}
	}
}
